import React from 'react';
import {
  FaHtml5,
  FaReact,
  FaCss3Alt,
  FaJava,
  FaGitAlt,
  FaJira,
} from 'react-icons/fa';
import { AiFillGithub } from 'react-icons/ai';
import {
  SiNextdotjs,
  SiJavascript,
  SiJest,
  SiJunit5,
  SiSpringboot,
  SiStyledcomponents,
  SiBootstrap,
  SiTypescript,
  SiTailwindcss,
} from 'react-icons/si';
import { DiMysql, DiMongodb } from 'react-icons/di';

const skillsIcons = [
  { icon: <SiJavascript size={70} /> },
  { icon: <SiTypescript size={70} /> },
  { icon: <FaReact size={70} /> },
  { icon: <SiNextdotjs size={70} /> },
  { icon: <FaHtml5 size={70} /> },
  { icon: <FaCss3Alt size={70} /> },
  { icon: <SiStyledcomponents size={70} /> },
  { icon: <SiBootstrap size={70} /> },
  { icon: <SiTailwindcss size={70} /> },
  { icon: <AiFillGithub size={70} /> },
  { icon: <FaGitAlt size={70} /> },
  { icon: <FaJira size={70} /> },
  { icon: <SiJest size={70} /> },
  { icon: <FaJava size={70} /> },
  { icon: <SiJunit5 size={70} /> },
  { icon: <SiSpringboot size={70} /> },
  { icon: <DiMysql size={70} /> },
  { icon: <DiMongodb size={70} /> },
];

const Skills = () => {
  return (
    <>
      {skillsIcons.map((val, i) => (
        <div className="col col-sm-2 mb-3 mb-sm-5" align="center" key={i}>
          {val.icon}
        </div>
      ))}
    </>
  );
};

export default Skills;
