import React from 'react';

import { SiLinkedin, SiInstagram, SiGithub } from 'react-icons/si';

const SocialShare = [
  {
    iconName: <SiLinkedin />,
    link: 'https://www.linkedin.com/in/franjseco/',
  },
  {
    iconName: <SiGithub size={20} />,
    link: 'https://github.com/FranjSeco',
  },
  {
    iconName: <SiInstagram />,
    link: 'https://www.instagram.com/frankalexandersupertramp/',
  },
];
// link: 'https://www.instagram.com/frankalexandersupertramp/',
const Social = () => {
  return (
    <ul className="social list-unstyled pt-1 mb-5">
      {SocialShare.map((val, i) => (
        <li class="d-inline-flex p-2" key={i} align="center">
          <a
            className="d-flex justify-content-center align-items-center"
            href={val.link}
            align="center"
            target="_blank"
            rel="noreferrer"
          >
            {val.iconName}
          </a>
        </li>
      ))}
    </ul>
  );
};

export default Social;
