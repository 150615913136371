import React from 'react';
import Modal from 'react-modal';
import { /*Tab, */ Tabs /*TabList*/ } from 'react-tabs';

import DirectionReveal from 'direction-reveal';
import ModalElement from './modal/ModalElement';

const projectsData = [
  {
    client: 'All-Correct',
    src: 'https://all-correct.vercel.app/',
    cover: './images/allcorrect.jpg',
    title: 'All-Correct',
    stack: 'NextJS, Styled-Components, Framer Motion',
    textOne:
      'The allcorrect website is created using Next.js, a front-end React framework; styled-components, a styling component; and Framer Motion, an animation library.',
    repo: 'https://github.com/sayus2884/All_Correct',
  },
  {
    client: 'Prim-U',
    src: 'https://prim-u.vercel.app/',
    cover: './images/primu.png',
    title: 'Prim-U',
    stack: 'NextJS, Styled-Components, Contentful',
    textOne:
      'Prim-U is a beauty & wellness, platform that connects professional service providers and suppliers with curstomers. This was the first project ever I had the oportunity to see the entire process from the beggining to the end, starting with the figma design handled by the the web designer, until the presentation of our work to the owner of the company.',
    repo: 'https://github.com/enyelsequeira/prim-u',
  },
  {
    client: 'Apiary',
    src: 'https://franjseco.github.io/apiary/',
    cover: './images/apiary.png',
    title: 'Apiary',
    stack: 'ReactJS, Styled-Components, Contentful',
    textOne:
      'Apiary is a Yandex project that offer international companies to build or renew their website by Practicum’s students. This project allows students to get experience in real world project. The project uses a CMS that allowes the client to add, modify or remove information in certain sections of the page.',
    repo: 'https://github.com/olivcamj/apiary',
  },
  {
    client: 'Practicum By Yandex',
    src: 'https://relaxed-mccarthy-43c30d.netlify.app/',
    cover: './images/worldsnews.png',
    title: "World's news",
    stack: 'ReactJS, CSS-in-JS, MongoDB, REST API',
    textOne:
      "Service that uses the NewsApi’s API to search for news around the world on a given keyword. I allows to save and remove articles within the user's profile. In order to do that the user should register and login, otherwise the system page would not allowed the user to use this extra functionality.",
    repo: 'https://github.com/FranjSeco/news-explorer-frontend/tree/stage-3',
  },
  {
    client: 'Practicum By Yandex',
    src: 'https://franjseco.github.io/web_project_3/',
    cover: './images/portland.png',
    title: 'From Portland to Portland',
    stack: 'HTML, JavaScript, CSS, BEM',
    textOne:
      'Prim-U is a beauty & wellness, platform that connects professional service providers and suppliers with curstomers. This was the first project ever I had the oportunity to see the entire process from beggining to the end, starting with the figma design handled by the the web designer, until the presentation of our work to the owner of the company.',
    repo: 'https://github.com/FranjSeco/web_project_3',
  },
  {
    client: 'Practicum By Yandex',
    src: 'https://franjseco.github.io/web_project_4/',
    cover: './images/aroundusexternalapi.png',
    title: "Around US Practicum's Api",
    stack: 'ReactJS, CSS, BEM, MongoDB, REST API',
    textOne:
      "It is a blog like web that allows to share images between different users. It also allows to like, dislike and remove images. This version of the project is using Practicum's API for storing user's information and images.",
    repo: 'https://github.com/FranjSeco/web_project_4',
  },
  {
    client: 'Practicum By Yandex',
    src: 'https://serene-panini-fd9869.netlify.app',
    cover: './images/aroundusbackend.png',
    title: 'Around US Sign Up and Sign In',
    stack: 'ReactJS, CSS, BEM, MongoDB, REST API',
    textOne:
      'This version of the project has implemented a REST API that allows to register and login. You can test it out by register and login with any email. It also uses MongoDB Atlas. This allows to store all the page info and cards added by the users in its own data base.',
    repo: 'https://github.com/FranjSeco/react-around-api-full',
  },
];

const Portfolio = () => {
  const [elementInOverlay, setElementInOverlay] = React.useState({});
  const [isOpen, setIsOpen] = React.useState(false);

  function toggleModal() {
    setIsOpen(!isOpen);
  }

  // const clients = projectsData.map((element) => {
  //   return element.client;
  // });

  // const uniqueClientsNames = Array.from(new Set(clients));

  // // console.log(uniqueClientsNames);

  React.useEffect(() => {
    // Init with default setup
    DirectionReveal();

    // Init with all options at default setting
    DirectionReveal({
      selector: '.direction-reveal',
      itemSelector: '.direction-reveal__card',
      animationName: 'slide',
      animationPostfixEnter: 'enter',
      animationPostfixLeave: 'leave',
      touchThreshold: 500,
    });
  }, []);

  return (
    <Tabs>
      {/* <TabList className="portfolio-tab-list-light">
        <Tab>ALL</Tab>
        {uniqueClientsNames.map((element) => {
          return <Tab className="--light">{element}</Tab>;
        })}
      </TabList> */}

      <div className="portfolio-tab-content">
        <ul className="row grid justify-content-center">
          {projectsData.map((element, i) => {
            return (
              <li
                data-aos="fade-right"
                data-aos-duration="1200"
                data-aos-delay="0"
                key={i}
                className="direction-reveal"
                style={{
                  overflow: 'hidden',
                  display: 'flex',
                  height: '163px',
                }}
              >
                <figure
                  className="direction-reveal__card"
                  onClick={() => {
                    setElementInOverlay(element);
                    toggleModal();
                  }}
                  style={{
                    backgroundColor: 'black',
                    boxShadow: '0px 10px 13px -7px rgba(114, 182, 38, 0.58)',
                  }}
                >
                  <img src={element.cover} alt="Project Thumbnail" />
                  {/* <h2>{element.title}</h2> */}
                  <div className="hover-content-wrapper direction-reveal__overlay direction-reveal__anim--enter">
                    <span className="direction-reveal__title content-title">
                      {element.title}
                    </span>
                  </div>
                </figure>
              </li>
            );
          })}
        </ul>
        <Modal
          isOpen={isOpen}
          onRequestClose={toggleModal}
          contentLabel="My dialog"
          className="custom-modal dark green"
          overlayClassName="custom-overlay"
          closeTimeoutMS={500}
        >
          <div>
            <button className="close-modal" onClick={toggleModal}>
              <img src="/img/cancel.svg" alt="close icon" />
            </button>
            {/* End close icon */}

            <div className="box_inner portfolio">
              <ModalElement props={elementInOverlay} />
            </div>
          </div>
        </Modal>
      </div>
    </Tabs>
  );
};
export default Portfolio;
