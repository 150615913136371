import React from 'react';

const educationContent = [
  {
    year: '2008',
    degree: 'BACHELOR DEGREE',
    institute: 'SEVILLE UNIVERSITY',
    details: `  Degree in Geography`,
  },
  {
    year: '2020',
    degree: 'WEB DEVELOPMENT COURSE',
    institute: 'PRACTICUM BY YANDEX',
    details: `Web development project based course focused on React and Node/Express.`,
  },
  {
    year: '2021',
    degree: 'FRONT END CERTIFICATION',
    institute: 'LUCATIC',
    details: `Front end course focused on Angular`,
  },
  {
    year: '2021',
    degree: 'BACK END CERTIFICATION',
    institute: 'LUCATIC',
    details: `Back end course focused on Java with Springboot, cloud and microservices`,
  },
];

const Education = () => {
  return (
    <ul>
      {educationContent.map((val, i) => (
        <li key={i}>
          <div className="icon">
            <i className="fa fa-briefcase"></i>
          </div>
          <span className="time open-sans-font text-uppercase">{val.year}</span>
          <h5 className="poppins-font text-uppercase">
            {val.degree}
            <span className="place open-sans-font">{val.institute}</span>
          </h5>
          <p className="open-sans-font">{val.details}</p>
        </li>
      ))}
    </ul>
  );
};

export default Education;
